<nav mat-tab-nav-bar [style.background-color]="theme.getColor('Main Dark')" class="facturation"
   *ngIf="router.url.includes('facturation')" [tabPanel]="tabPanel">
   <a mat-tab-link [ngClass]="{'current' : router.url.includes('facturation-list')}"
      [ngStyle]="authenticationService.isUserAdmin()? {}: {'display':'none'}"
      routerLink="/core/facturation/facturation-list"> Mise en facturation </a>
   <a mat-tab-link [ngClass]="{'current' : router.url.includes('facturation-import')}"
      [ngStyle]="authenticationService.isUserAdmin()? {}: {'display':'none'}"
      routerLink="/core/facturation/facturation-import"> Import factures </a>
   <a mat-tab-link [ngClass]="{'current' : router.url.includes('facturation-netting_regularisation')}"
      [ngStyle]="authenticationService.isUserAdmin()? {}: {'display':'none'}"
      routerLink="/core/facturation/facturation-netting"> Netting & Régularisation </a>
   <a mat-tab-link [ngClass]="{'current' : router.url.includes('facturation-vue_netting')}"
      routerLink="/core/facturation/facturation-vue_netting"> Vue Netting </a>
   <div class="flex-grow"></div>
   <a (click)="openGuide(authenticationService.isUserAdmin()?'facturationGuideAdmin':'facturationGuide')">
      <div class="material-icons help-icon">help_outline</div>
   </a>
</nav>

<mat-tab-nav-panel #tabPanel>
   <!-- Contenu des onglets ici -->
</mat-tab-nav-panel>